const RadioCircleUnfilledIcon = ({
	size = 'large',
	invertTheme = false,
	...props
}: {
	size: 'small' | 'large'
	invertTheme?: boolean
}) => (
	<svg
		width={size === 'small' ? 16 : 24}
		height={size === 'small' ? 16 : 24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x="0.5"
			y="0.5"
			width="23"
			height="23"
			rx="11.5"
			className={
				invertTheme
					? 'fill-uiTertiaryDark dark:fill-uiSecondaryLight'
					: 'fill-uiSecondaryLight dark:fill-uiTertiaryDark'
			}
			//   fill="currentColor"
		/>
		<rect
			x="0.5"
			y="0.5"
			width="23"
			height="23"
			rx="11.5"
			//   stroke="red"
			className={
				invertTheme
					? 'stroke-grey800 hover:stroke-grey500 group-hover:stroke-grey500 dark:stroke-grey400 dark:hover:stroke-textSecondaryLight group-hover:dark:stroke-textSecondaryLight'
					: 'stroke-grey400 hover:stroke-textSecondaryLight group-hover:stroke-textSecondaryLight dark:stroke-grey800 dark:hover:stroke-grey500 group-hover:dark:stroke-grey500'
			}
		/>
	</svg>
)

export default RadioCircleUnfilledIcon
